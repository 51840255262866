import React, { Component } from 'react';
import { MainHeading } from '../Style';
import Reference from './Reference';
import { references } from './referencesFile';

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

class References extends Component {
  render() {
    return (
      <div>
        <MainHeading>References</MainHeading>
        <div>
          {references.map(({ quote, person }) => (
            <Reference
              quote={quote}
              person={person}
              side={getRandomInt(2) === 0 ? 'right' : 'left'}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default References;
