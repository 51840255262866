import React, { Component } from 'react';
import { Content, MainHeading } from '../Style';

class Fees extends Component {
  render() {
    return (
      <>
        <MainHeading>General Fees and Opening Hours</MainHeading>
        <Content>
          My fees start at £5 per hour for during the day care. I charge a minimum of four hours per day. Fees are paid monthly in advance and payment is due on the 1st of the month. Invoices will be issued on or after the
          20th day of the month prior to payment being due.
          <br />
          I am open Monday to Friday, 7am – 7pm.
          <br />
          I am able to accommodate a later finish or start time by prior
          agreement.
          <br />
          I will be closed on public holidays. Any additional holiday or time
          off taken by me due to illness will be of no charge to you. I aim to
          give you as much notice as possible for my intended holiday dates. I
          can help you seek alternative childminders to cover these if you wish
          me to do so. Their normal fees will apply and any agreement is between
          you and them.
          <br />
          Weekly payment can be arranged, but by prior agreement only and at my
          discretion.
        </Content>
        <MainHeading>Before and After School Care</MainHeading>
        <Content>
          My fee is £6 per hour for before and after school care, with a minimum
          of 1 hours payment for after school. If your child comes to my setting
          before school you will be charged until 9am. If your child comes after
          school, you will be charged from 3pm.
        </Content>
        <MainHeading>
          Preschool and Nursery Drop - off and Collection
          {' '}
        </MainHeading>
        <Content>
          If your child attends preschool or nursery, I will be happy to drop
          off and collect if it is discussed and agreed in advance. You will
          still be charged for the hours your child is at Nursery or pre school
          as it will be unlikely that i will be able to fill this space for the
          short hours.
          <br />
          The space will be available should the preschool or nursery be closed.
        </Content>
      </>
    );
  }
}

export default Fees;
