import React, { Component } from 'react';
import styled from 'styled-components';

const ReferenceContainerLeft = styled.div`
  padding: 25px;
  border: 2px;
  border-color: #007bfe;
  border-style: solid;
  margin: 25px;
  box-shadow: 3px 3px darkkhaki;
  background-color: oldlace;
  margin-left: 200px;
`;

const ReferenceContainerRight = styled.div`
  padding: 25px;
  border: 2px;
  border-color: #007bfe;
  border-style: solid;
  margin: 25px;
  box-shadow: 3px 3px darkkhaki;
  background-color: oldlace;
  margin-right: 200px;
`;

const ReferencePerson = styled.text`
  padding-top: 10px;
  color: #3185cb;
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  font-size: 24px;
`;

const ReferenceQuote = styled.text`
  font-family: Pacifico, cursive;
  font-size: large;
  color: #8fbfff;
`;

class Reference extends Component {
  render() {
    if (this.props.side === 'right') {
      return (
        <>
          <ReferenceContainerRight>
            <ReferenceQuote>
              `"`
              {this.props.quote}
`"`
              <br />
            </ReferenceQuote>
            <ReferencePerson>
              {this.props.person}
              <br />
            </ReferencePerson>
          </ReferenceContainerRight>
        </>
      );
    }
    return (
      <>
        <ReferenceContainerLeft>
          <ReferenceQuote>
              `"`
            {this.props.quote}
`"`
            <br />
          </ReferenceQuote>
          <ReferencePerson>
            {this.props.person}
            <br />
          </ReferencePerson>
        </ReferenceContainerLeft>
      </>
    );
  }
}

// Reference.propsType = {
//   side: propsType.string.isRequired,
//   quote: propsType.string.isRequired,
//   person: propsType.string.isRequired
// };

export default Reference;
