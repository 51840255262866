import React from 'react';
import { Content, SubHeading } from '../Style';

const sickeness = () => (
  <div>
    <SubHeading>
      Sickness Policy
    </SubHeading>
    <Content>
      I am happy to care for children with minor coughs and colds but will refuse admission to children who are very
      unwell, infectious or running a high temperature, including head lice and conjunctivitis. If your child is unwell
      then they will be better cared for in their own home with a parent. I have to go out each day to do school and
      pre-school runs and need to consider the welfare of all the children in my care.
    </Content>
    <Content>
      If your child has had diarrhoea or sickness please do not bring them, but call and let me know. Your child may not
      return to my setting until they have had 48 hours clear of any sickness or diarrhoea. This is in line with West
      Sussex guidelines.
    </Content>
    <Content>
      If your child becomes ill whilst in my care, I will make them as comfortable as possible, isolate them from the
      other children if necessary and reassure them. I will contact you immediately and continue to care for your child
      until you arrive.
    </Content>
    <Content>
      <b>I am happy to administer medication – please see my medicine policy.</b>
    </Content>
    <Content>
      I will contact you if my own child is not well, inform you of the illness/symptoms and if I am able to work. This
      then allows you to make an informed decision as to whether to bring your child or not. If I am able to work but
      you chose for your child not to attend, full fees are still payable. I am happy to work when I have minor ailments
      such as colds etc however I will not work if I have sickness or diarrhoea or any other contagious infection.
      Continuing to work would only result in a further spreading of the germs and I would be unable to give your child
      the care they deserve.
    </Content>
    <Content>
      If you require me to do so, I can arrange a pool of emergency cover with other Ofsted registered childminders who
      would be happy to care for your child whilst I am unwell, providing it doesn’t take them over their allowed
      numbers. This needs to be arranged at the start of our contract. These childminders, their homes and the children
      they care for will be known to your child. If you wish to meet with them beforehand, please let me know.
    </Content>
    <Content>
      If I contact you to inform you that I am unwell and therefore unable to work, you are free to call any of the
      above to arrange cover. I do not charge if I am unable to work through illness and the above-mentioned
      childminders will charge you at their agreed rate for the day(s) they cover.
    </Content>
    <Content>
      I will ensure that any spare medicines stored by me are labelled and have not passed the export date.
    </Content>
  </div>
);

export default sickeness;
