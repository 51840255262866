import React from 'react';
import { Content, SubHeading } from '../Style';

const transportation = () => (
  <div>
    <SubHeading>
      Transporting Children in a Car Policy
    </SubHeading>
    <Content>
      The safety of your child is of utmost importance so I have therefore put together the following procedures
      regarding the transportation of children in my car.
    </Content>
    <Content>
      I have a full, clean driving licence, which is available for you to see on request. I will ensure my car is in
      road worthy condition by having it regularly serviced by a reputable garage. I will ensure my car complies with
      all legislation, MOT, car Tax etc. I will ensure my car is covered by business use car insurance and the
      certificate is available for you to see. I will avoid where possible taking your child to a petrol station, by
      filling up my car before or after my minded hours. I will ensure all car seats are correctly fitted, age and stage
      appropriate for the children using them and that they are correctly strapped into them. I will ensure that the
      child safety locks are in use on my car. I will always carry a mobile telephone with me in case of an emergency
      but will not answer it whilst driving. I will return calls on my return. I will always carry identification for me
      and the minded children in case of an emergency. I will always carry a First Aid kit in the car in case of an
      emergency. I will never leave a child unattended in a car. I will teach your child about the dangers of cars and
      roads, in an age appropriate way.
    </Content>
    <Content>
      <b>
        Please let me know if your child suffers from travel/motion sickness.
      </b>
    </Content>
  </div>
);

export default transportation;
