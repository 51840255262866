import React from 'react';
import { Content, SubHeading } from '../Style';

const inclusion = () => (
  <div>
    <SubHeading>Inclusion Policy</SubHeading>
    <Content>
      I follow the requirements of the Early Years Foundation Stage (EYFS) and provide an inclusive
      environment for all
      children and their families. I am also required to comply with the requirements of the
      Equality Act 2010 and the
      Special Educational Needs and Disability (SEND) Code of Practice 2014. I give all children in
      my care the
      opportunity to reach their full potential. I will provide a range of challenging and enjoyable
      opportunities for
      each child to learn and develop to their full potential. Taking into account age and stage of
      development, gender,
      ethnicity, religion, home language and ability. Sometimes this means adapting an activity for
      a child, providing
      additional resources or giving one child more attention and support than others during a
      particular activity or
      routine.
    </Content>
    <Content>
      I will provide and make sure that all children have access to a range of books, toys and other
      resources which
      reflect positive images and examples of diversity of life in our society. No toys are just for
      girls or just for
      boys. I have toys and resources that challenge stereotypical ideas on what careers are open to
      men and women. No
      child in my care will be discriminated against in anyway, whether for their skin colour,
      culture, gender, ability
      or
      religion.
    </Content>
    <Content>
      I will challenge any remarks that I feel are inappropriate. I will work closely with parents
      to listen to their
      views so as to build on children’s previous experiences, knowledge, understanding and skills
      and provide
      opportunities to develop in the six areas of learning:
      <li>Personal</li>
      <li>social and emotional development</li>
      <li>Communication, language and literacy</li>
      <li> Mathematical development</li>
      <li>Knowledge and understanding of the world</li>
      <li>Physical development</li>
      <li>Creative development</li>
    </Content>
    <Content>
      I will observe and monitor individual children’s progress throughout the Foundation Stage and
      where a child
      appears
      not to be making progress either generally or in a specific aspect of learning I will present
      them with different
      opportunities or use alternative ways of learning. I will discuss my observations and any
      proposed plans with the
      parents in a way that encourages them to contribute their knowledge and understanding of their
      child and allows
      them
      to raise any concerns they may have about their child’s needs and the provision that is being
      made for them. I
      will
      ensure every child receives the best possible care and learning development. I will seek
      support from PACEY and
      the
      Area Special Educational Needs Coordinator (SenCo) and will liaise with external agencies and
      specialist services
      for support, advice or resources as appropriate for the child’s needs. I encourage the
      children in my care to
      learn
      more about their own culture and to find out about the culture and religions of other
      children. We do in this in a
      fun way through sharing books, colouring sheets, cooking and eating food from around the world
      and celebrating
      special festivals. I provide resources such as chopsticks, dressing up clothes and
      multicultural skin tone crayons
      and pencils.
    </Content>
    <Content>
      I will promote self esteem and appreciation of others by celebrating the differences which
      makes us all unique. I
      encourage the children to develop a healthy respect of each other’s differences and to value
      everyone as an
      individual. I encourage Parents to share with us any festivals, special occasions or
      artifacts, which may enhance
      the children’s learning and understanding. I challenge racist and other discriminatory
      remarks, attitudes and
      behaviour from all adults and children with whom i make contact with. This Policy supports the
      requirements and
      standards of the Early Years Foundation Stage Safeguarding and Welfare requirements 2014. I
      will regularly review
      my risk assessments to ensure accessibility and reasonable adjustments are made. I will ensure
      that my business
      policies and documents are accessible to all.
    </Content>
    <SubHeading>Inclusion</SubHeading>
    <Content>
      I will promote the British values of democracy, rule of law, individual liberty and mutual
      respect and tolerance
      of
      different faiths and beliefs through my delivery of the EYFS. I will be proactive in providing
      care to all
      children
      regardless of their individual needs, and consider reasonable adjustments to equipment and
      routines where
      necessary.
      I am happy to give medication but where this requires medical or technical knowledge I must
      have training by a
      health care professional before administering this. I will regularly review, monitor and
      evaluate my practice and
      keep up to date by attending relevant training.
    </Content>
  </div>
);

export default inclusion;
