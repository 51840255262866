import React from 'react';
import { Content, SubHeading } from '../Style';

const safeguarding = () => (
  <div>
    <SubHeading>
      Safeguarding Children Policy
    </SubHeading>
    <Content>
      My first responsibility and priority is towards the children in my care. If I have any cause
      for concern I will
      report it to the relevant body, following the Local Safeguarding Children Board procedures. I
      understand that
      child abuse can be physical, sexual, emotional, neglect, or a mixture of these, and am aware
      of the signs and
      symptoms of these. I must notify Ofsted of any allegations of abuse that are alleged to have
      taken place while the
      child is in my care, including any allegations against me, or any members of my family, or
      other adults or
      children in my home who have had contact with minded children.
    </Content>
    <SubHeading>
      Procedure
    </SubHeading>
    <Content>
      I keep up to date with child protection issues and relevant legislation by taking regular
      training courses and by
      reading relevant publications from PACEY. This maintains my knowledge of the signs of abuse or
      neglect and what to
      do if I have a concern.
    </Content>
    <Content>
      I hold copies of, and am familiar with, the Local Safeguarding Children Board procedures. I
      regularly check that I
      have the latest version of the relevant procedures (or any documents that may replace them in
      the future). I
      follow the procedures outlined in my confidentiality policy. I work together with parents to
      make sure the care of
      their child is consistent (please refer to my working with parents policy.) I also work with
      other organisations
      and individuals as set out in the Working Together to Safeguard Children guidance. Children
      will only be released
      from my care to the parent/carer or to someone named and authorised by them. A password might
      be used to confirm
      identity if the person collecting the child is not previously known to me. Parents must notify
      me of any concerns
      they have about their child and any accidents, incidents or injuries affecting the child,
      which I will record and
      ask parents to sign. If I am concerned about a child’s welfare, I will contact the local
      authority first contact
      team, the NSPCC, PACEY, or other relevant support services for advice. Confidentiality will be
      assured only when
      it is clear that there is no risk of harm to a child.
    </Content>
    <Content>
      In all instances I will record:
    </Content>
    <Content>
      The child’s full name and address. The date and time of the record. Factual details of the
      concern, for example
      bruising, what the child said, who was present. Details of any previous concerns. Details of
      any explanations from
      the parents. Any action taken such as speaking to parents. Unless I believe that it would put
      the child at risk of
      further harm, I will discuss concerns with a child’s parent if I notice: Significant changes
      in a child’s
      behaviour. Bruising or marks not consistent with a reported accident. Any comments made by a
      child which give me
      cause for concern. Signs of neglect.
    </Content>
    <Content>
      If a child tells me that they or another child is being abused, I will:
    </Content>
    <Content>
      Show that I have heard what they are saying, and that I take their allegations seriously.
      Encourage the child to
      talk, but I will not prompt them or ask them leading questions. I will not interrupt when a
      child is recalling
      significant events and will not make a child repeat their account. Explain what actions I must
      take, in a way that
      is appropriate to the age and understanding of the child. Record what I have been told using
      exact words where
      possible. Make a note of the date, time, place and people who were present at the discussion.
      I will call the
      local social services’ duty desk for advice and an assessment of the situation. I will follow
      this phone call up
      with a letter to the Duty team within 48 hours. I will record the concern and all contact with
      Children’s Services
      thereafter.
    </Content>
    <SubHeading>
      Sussex Child Protection and Safeguarding procedure
    </SubHeading>
    <Content>
      West Sussex Access Point, Monday to Friday, 9am-5pm, 01403 229900, Out of hours: 03302 226664
    </Content>
    <SubHeading>
      Procedure for Allegations of Abuse against a Childminder
    </SubHeading>
    <Content>
      As a childminder working alone I am more vulnerable to allegations of abuse being made against
      my family or me. If
      an allegation is made against me, any member of my family or any other adult or child in my
      setting I will report
      it to Ofsted and social services, following the Local safeguarding Children Board procedures.
      I will also contact
      PACEY’s Information Line on 0845 880 0044 for advice and support.
    </Content>
    <SubHeading>
      I will take precautions to protect myself from this happening by:
    </SubHeading>
    <Content>
      Ensuring all household members over 16 have a valid DBS certificate. Ensuring all visitors to
      the house sign the
      visitors book and do not have unsupervised access to the children under any circumstances.
      Ensuring, where
      possible, that no workmen are in the house during minded hours, unless it is to repair an
      emergency service or for
      Health and Safety Reasons. Document every accident and incident that occurs whilst in my care,
      informing parents
      and requesting them to sign my records. Noting any marks on the children when they arrive and
      asking parents to
      inform me of any accidents that have occurred whilst outside my care. Ensuring the children
      are supervised at all
      times. It is not my responsibility to attempt to investigate the situation myself.
    </Content>
    <Content>
      LADO: Rosemary Terry (Local Authority Designated Officer). Assistant LADO: Claire Coles.
      Address: Safeguarding in
      Education and Allegations Management Team, Room 24, Durban House, Durban Road, Bognor Regis,
      PO22 9RE Telephone:
      03302 223339
    </Content>
  </div>
);

export default safeguarding;
