import React from 'react';
import { Content, SubHeading } from '../Style';

const sleepingbaby = () => (
  <div>
    <SubHeading>
      Sleeping Baby Policy
    </SubHeading>
    <Content>
      I will discuss with you in detail the sleep routines you have established for your child and how I can best
      accommodate them into my own routine and working day. These discussions will need to be ongoing as the sleep needs
      of your child will change as s/he grows and develops.
    </Content>
    <Content>
      It is vital that I work in partnership with you to provide a sleep routine for your child that fits your
      requirements.
    </Content>
    <Content>
      I will need to know how many naps your child normally has, for how long and at what times during the day. When is
      the latest I can let your child sleep until without it affecting their night time routine? Do you want me to wake
      your baby after a certain length of time or can I let him/her sleep on? What routine do you have to settle your
      child? Do they have a dummy or comforter? Where does your baby prefer to sleep? I will regularly check your baby
      when s/he is sleeping, both in person and by using a baby monitor.
    </Content>
    <Content>
      If you ever have any concerns over your child’s sleeping routine please do discuss them with me and we can make
      arrangements to change times etc if necessary.
    </Content>
  </div>
);

export default sleepingbaby;
