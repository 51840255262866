import React from 'react';
import { Content, SubHeading } from '../Style';

const drugs = () => (
  <div>
    <SubHeading>Alcohol, Smoking and Drugs Policy</SubHeading>
    <Content>
As a Ofsted registered childminder I work alone and will have sole responsibility for your child whilst
      they are in my care.
    </Content>

    <Content>
It is vital that I am alert to any dangers and able to protect him/her. In order to do this effectively I
      will not be under the influence of alcohol or any form of drugs. I will not drink any alcohol during minded hours
      or immediately before.
    </Content>

    <SubHeading>Children</SubHeading>
    <Content>
Drugs and alcohol are now more readily available to younger children. If I have any concerns that your
      child may be drinking alcohol, taking drugs or smoking I will discuss the matter with you immediately. I will then
      work with you to support your child, however I reserve the right to terminate our contract with immediate effect
      if I am concerned that your child’s behaviour due to drugs/alcohol may be putting the other minded children at
      risk.
      {' '}
    </Content>
    <Content>
In accordance with the National Standards produced by the Department for Education and
      Skills I have a no smoking policy in my home. No one is permitted to smoke in my home and I will not take the
      children into smoky environments and will avoid places that permit smoking wherever possible.
    </Content>
  </div>
);

export default drugs;
