import React, { Component } from 'react';
import { Content, MainHeading } from '../Style';

class Rules extends Component {
  render() {
    return (
      <>
        <MainHeading>House Rules</MainHeading>
        <Content>
          I have a small number of simple ‘House Rules’. They are to help be
          together and stay safe and happy. I work with the children to ensure
          that they understand the rules and why they are in place. They are
          probably very similar to the ‘rules’ that you have in your own home.
        </Content>
        <Content>
          <ul>
            <li>
              We all sit at the table (or in our high-chair) to eat or drink.
            </li>
            <li>
              We treat the furniture with respect by sitting nicely on chairs
              and sofas so we don’t fall off and hurt ourselves.
            </li>
            <li>
              We look after the toys and play with them correctly. We do not
              throw toys as this could hurt someone and/or break the toy.
            </li>
            <li>We remove our shoes by the front door.</li>
            <li>
              We treat others how we would like to be treated. We learn good
              manners, say please and thank you, and are nice to each other by
              being kind
            </li>
            <li>
              We share our toys and activities and help to tidy away when
              finished with them.
            </li>
          </ul>
        </Content>
      </>
    );
  }
}

export default Rules;
