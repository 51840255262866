import React from 'react';
import { Content, SubHeading } from '../Style';

const television = () => (
  <div>
    <SubHeading>
      Television Policy
    </SubHeading>
    <Content>
      I limit the time children in my care are permitted to watch television. The watching of television programmes,
      DVDs and streaming of programs is normally restricted to:
    </Content>
    <Content>
      A short session after lunch to provide the children with a quiet time to rest and allow their dinner to go down. A
      short session after the school pick up to give the older children an opportunity to re charge their batteries. If
      a child is feeling a little off colour and needing more rest then it may be appropriate for them to watch more
      television that day. I ensure that any programmes watched are suitable for all the children in my care. If your
      child wants to bring a DVD / Bluray to watch at my house please check with me first as it must be appropriate
      viewing for the younger children.
    </Content>
  </div>
);

export default television;
