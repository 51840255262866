import React from 'react';
import { Content, SubHeading } from '../Style';

const accident = () => (
  <div>
    <SubHeading>Accident / Incident Policy</SubHeading>
    <Content>
      The safety of your child is paramount and I will take every measure I can to protect your
      child from harm,
      however sometimes accidents do happen and I have written the following procedure on how I will
      deal with such a
      situation:I will comfort the child and reassure them, I will assess the extent of their
      injuries and if necessary
      call for medical support/ambulance. I will carry out any first aid procedures that are
      necessary and that I have
      been trained to do. I will always have an up to date First Aid Certificate. I will always have
      a fully stocked and
      accessible first aid box in my setting, that will be located in the kitchen and a separate one
      that will be taken
      out on every outing. I will always have a fully stocked and accessible first aid box in my
      setting, that will be
      located in the kitchen and a separate one that will be taken out on every outing. Once the
      child is settled I will
      contact you as soon as possible to inform you of the accident and if necessary to ask you to
      return to care for
      your child or meet me at the hospital. I will ask my assistant who is a DBS checked adult to
      look after the
      remaining children while I am caring for the injured or sick child. My assistant will be
      familiar to all parents
      and the settling.
    </Content>

    <Content><b>My assistant is Janet Bushell (Carrie’s Mother).</b></Content>
    <Content>
      After every accident, however minor I will complete a report in my accident book. Ask you to
      sign the
      report and then provide you with a copy. If you child arrives at my Setting with an existing
      injury that occurred
      in your care, I will also fill in my accident book as ask you to sign it.
    </Content>

    <SubHeading>If the incident requires any medical treatment</SubHeading>
    <Content>
I will Inform Ofsted (under Standard 14.3 Children Act regulation) about any
      significant events. Inform my
      Insurance Company. Contact Pacey for additional advice / support. It is important that you
      keep me informed
      regarding your child’s condition following an accident and if you have sought medical
      advice.
    </Content>

    <SubHeading>Emergencies Policy And Procedure</SubHeading>
    <Content>
My premises have been checked by Ofsted and they meet the requirements of the Early
      Years Foundation Stage.
      I also regularly review, update and practise my safety routines, including fire drills. I hold
      written permission
      from parents (medical and emergency treatment consent form) to seek emergency treatment for
      their child if it is
      needed.
    </Content>

    <SubHeading>In the event of an emergency, the following will apply:</SubHeading>
    <Content>
Remain calm and not panic. Check safety of all other children and adults. Evacuate the
      setting immediately,
      making sure that all the children and adults are present. I will telephone 999. I will contact
      each parent and ask
      for their child or children to be collected. If I have an accident, I will get the nearest
      responsible adult to
      help, while my emergency back-up is being contacted. I will stored all parents and carers
      contact details in my
      mobile phone so that they can be contact in the event of an emergency.
    </Content>

    <SubHeading>Incidents</SubHeading>
    <Content>
An incident is a non-medical emergency which causes the need for an immediate
      response/change of routine in
      the setting. An incident is a non-medical emergency which causes the need for an immediate
      response/change of
      routine in the setting.
    </Content>

    <Content>
I will use existing planned procedures as far as possible in the event of an incident
      which affects my
      setting as follows:
    </Content>

    <Content>
      <b>Missing child</b>
      {' '}
– I will follow my Missing Child policy and procedure
    </Content>
    <Content>
      <b>Gas leak/fire</b>
      {' '}
– I will follow my Emergency Evacuation procedure as detailed
      below
    </Content>
    <Content>
      <b>Challenging/aggressive behaviour of a child</b>
      {' '}
– I will follow my Behaviour
      Management policy and
      procedure
    </Content>
    <Content>
      <b>Flooding</b>
      {' '}
– I will monitor the situation and follow my Emergency Evacuation
      procedure if
      necessary
    </Content>
    <Content>
      <b>Threatening behaviour of an adult</b>
      {' '}
– I will seek to remove the children from the
      situation to a safe
      place and if necessary and when safe to do so I will call the Police on 999.
    </Content>
    <SubHeading>Terrorist Attack or National Emergency</SubHeading>
    <Content>
The care and security I provide to your child is paramount. As an Ofsted registered
      childminder I will do
      everything within my power to protect, comfort and support your child in the event of a major
      incident, national
      emergency or terrorist attack. If we are involved or caught up in the incident I will comply
      fully with the
      instructions from the emergency services and constantly reassure the children in my care. If
      you are caught up in
      an incident I will continue to look after your child until you are able to return or an
      authorised person is able
      to collect them. I understand that during major incidents the mobile phone networks are often
      not available and
      even landlines can be cancelled to free up communication systems for the emergency services. I
      will however
      attempt to contact you on a regular basis and ask that you try to do the same. I will keep up
      to date on the
      situation using any media source available to me, radio, television, Internet etc. I will
      endeavour to protect
      your child from information or images that may alarm or distress them. If you wish, we can
      have a pre-planned
      excuse that I can use to explain your delay in arriving.
    </Content>
  </div>
);

export default accident;
