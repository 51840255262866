import React from 'react';
import { Content, MainHeading } from '../Style';

export const contact = () => (
  <div>
    <MainHeading>Contact Me</MainHeading>
    <Content>Please give me a call on 07702 107430</Content>
    <Content>or</Content>
    <Content>Email me at:</Content>
    <Content>carrie@carriebevan-childminding.co.uk</Content>
  </div>
);

export default contact;
