import React from 'react';
import { Content, SubHeading } from '../Style';

const holidays = () => (
  <div>
    <SubHeading>
      Holiday Policy
    </SubHeading>
    <Content>
      I take 4 weeks holiday per year plus bank holidays. I am closed for the Christmas period, from the 23rd December
      at 6pm and reopen on 2nd January. I am happy to discuss working a bank holiday if enough notice is given and will
      charge £12 per hour. I do not charge for any holiday that I take. All holiday taken by parents and their child
      will be charged at full rate unless they take holiday during the same weeks as myself, in which case the parent
      will pay no fee.
    </Content>
  </div>
);

export default holidays;
