import React from 'react';
import { Content, SubHeading } from '../Style';

const medicine = () => (
  <div>
    <SubHeading>
      Medicine Policy
    </SubHeading>
    <Content>
      All medicine given to me to administer must be in its original container and not decanted.
      Prescription medication will need to be provided with the box, manufacturers guidelines and
      the details from the Doctor describing the child’s name, and dose required. All administration
      for medication will be done following the Statutory Framework for the Early Years Foundation
      Stage , Section 3.44-3.46.
    </Content>
    <SubHeading>
      Non-Prescription Medicines
    </SubHeading>
    <Content>
      I am happy to give your child non-prescribed medication, such as cough mixture, Calpol,
      Nurofen, Piriton,teething gel or nappy rash creams. However, for your child’s, yours and my
      own protection.
    </Content>
    <Content>
      Before I administer any medication to your child i will need written consent prior to the
      first dose, explaining dose, time of dose, if medication needs to be given before or after
      food, how the medication is to be stored and when the last dose was administered before
      arriving at my Setting. It is your responsibility to keep me informed of any changes or health
      conditions relevant to the administration of the agreed medicines. It is vital that you inform
      me of any medication you may have given your child before they arrive into my care, including
      what medicine they have had, the dose and time given. Despite all of the above, I will still
      contact you by telephone prior to administering any medicine.
    </Content>
    <Content>
      I will ensure that all medication given to me will be stored correctly either in the fridge
      (away from food) or in a cool dry place out of reach of all minded children. And I will check
      that it is within its expiry date. I will record all medication that is administered and
      request a parental signature at the end of each day
    </Content>
    <SubHeading>
      Prescription Medicines
    </SubHeading>
    <Content>
      If your child has a self-held medication such as an asthma inhaler or Epi-pen, please obtain
      an additional one for me to be kept at my home. These will be placed in a separate bag with
      your child’s name on a kept in an accessible place to me but out of reach to minded children.
      These medications will be taken with your children every where we go. If your child has acute
      allergies and requires an Epi-pen, please discuss the matter with me. I may need additional
      training to administer these forms of medication. If your child needs to take medication
      prescribed by a doctor, please discuss this with me. I will need you to sign an additional
      permission form. In some cases a child on antibiotics may be asked not to attend for 2-3 days
      in case they react to the medication and to prevent the spread of an infection to others.
    </Content>
    <Content>
      If there is a particular medication that your child has difficulty taking or refuses to take i
      would need to be informed of this. If i was to administer the medication and your child
      refuses or spits out the medication this would be document for you to sign. As i would have no
      way of knowing how much your child have swallowed i would not top up that particular dose but
      to wait until the next allocated dose time and try again. If this became a regular occurrence
      we would need to discuss an appropriate way for your child to successfully take the medicine.
      Any Visitor to my Setting, including Parents and carers will always be reminded to keep
      handbags or changing bags that may contain medication out of reach of children at all times to
      prevent any risk of children digesting any medication. I will always have our family
      medication locked in my Pantry in the kitchen which is not accessible to children.
    </Content>
  </div>
);

export default medicine;
