import React from 'react';
import { Content, SubHeading } from '../Style';

const schoolholiday = () => (
  <div>
    <SubHeading>
      School Holiday Care Policy
    </SubHeading>
    <Content>
      I am happy to provide care during school holidays for other children (i.e. Siblings) provided I stay within my
      ratios and have the place available, and I feel I can safely provide care for the children I already look after.
      The children who are already in my care take full priority when deciding if school holiday care can be provided.
    </Content>
    <Content>
      My hourly rate is the same per hour but I require a minimum of 5 hours per day.
    </Content>
  </div>
);

export default schoolholiday;
