import React from 'react';
import { Content, SubHeading } from '../Style';

const visitors = () => (
  <div>
    <SubHeading>
      Visitors Policy
    </SubHeading>
    <SubHeading>
      As an Ofsted registered childminder I am very aware of my role in keeping your child safe.
    </SubHeading>
    <Content>
      Whilst children need to mix with other children and adults it is my responsibility to ensure
      the suitability of
      those that they come into contact during minded hours.
    </Content>
    <SubHeading>
      I have therefore written the following policy regarding visitors in my setting during my
      minded hours.
    </SubHeading>
    <Content>
      I will not leave a minded child in a room alone with a visitor, unless I know they have been
      DBS checked, for
      example another Ofsted Registered Childminder. I will not allow any visitors to take my minded
      children to the
      toilet or change their nappies. I will request identification from all visitors not known to
      me and will refuse
      entry if I am unsure of them. I will endeavour, when possible, to arrange for any maintenance
      work to my property
      to be carried out at weekends and during non-minding hours.
    </Content>
  </div>
);

export default visitors;
