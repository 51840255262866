import React from 'react';
import { Content, SubHeading } from '../Style';

const parentpartnership = () => (
  <div>
    <SubHeading>
      Working in Partnership with Parents
    </SubHeading>
    <Content>
      It is very important for your child that we work in partnership. This will give your child continuity of care and
      (s)he will not become confused with different standards of behaviour and boundaries.
    </Content>
    <Content>
      As Parents you are the central adults in your child’s life and the ones making decisions on their behalf. I will
      endeavour to work closely with you in order to carry out your wishes for your child wherever I can. It is
      therefore important that we have an excellent communication system. I appreciate that as a working Parent you will
      be in a rush to go to work in the mornings and in the evenings you may well be tired and need to go as quickly as
      possible, so I provide a parent/childminder contact book for daily communications. I will complete a page each day
      that will include what your child has eaten, naps, activities, milestones achieved etc. I would request that you
      use this book to note down if your child has had a disturb sleep, is not feeling well or any other piece of
      information that may help me to provide him/her with the best care I can. It is important that this book comes
      with your child each day. I am always happy to discuss your child and their care with you at any time that is
      convenient to us both, whether in person or over the phone.
    </Content>
    <Content>
      I would also appreciate it if you could inform me if there are any changes to contact numbers for yourselves,
      including work and mobile numbers and those of your emergency contacts.
    </Content>
    <Content>
      As your child grows and develops, matters will crop up that are very important for us to discuss in order that we
      can work together and incorporate your wishes into my care routine for your child. These could include weaning,
      potty training, managing behaviour, starting (pre-)school etc.
    </Content>
    <Content>
      If you wish me to incorporate a special activity into my routine, perhaps a festival or religious holiday that you
      celebrate please let me know.
    </Content>
    <Content>
      If I have any concerns about your child’s behaviour, development, eating etc I will share them with you and if
      necessary work with you to seek support from outside agencies.
    </Content>
    <Content>
      If you have any concerns or issues regarding the care I am providing for your child please do let me know. Often a
      concern is a simple misunderstanding that can easily be resolved; un-aired it can fester and become a major issue.
    </Content>
  </div>
);

export default parentpartnership;
