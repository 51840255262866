import React from 'react';
import { FooterBox, FooterText } from '../Style';
import kids from './AdobeStock_97554267.png';

const currentYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear;
};

export const SiteFooter = () => (
  <>
    <img src={kids} width="100%" alt="lots of kids standing in a line" />
    <FooterBox>
      <FooterText>
        © Dean Bevan
        {currentYear()}
      </FooterText>
    </FooterBox>
  </>
);

export default SiteFooter;
