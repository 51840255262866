import React from 'react';
import { Content, SubHeading } from '../Style';

const collection = () => (
  <div>
    <SubHeading>Dropping off, Collection and Late Collection Policy</SubHeading>
    <Content>
Please let me know in advance if you intend to arrive at a time different from that
      which is contracted. If
      you
      arrive unexpectedly early I may not be ready to care for your child. If you are late, or know
      that you will be
      late,
      I ask that you let me know as soon as possible, preferably before or at your contracted drop
      off time. If I am
      unable to wait, you may be asked to drop off at an alternative location e.g. Toddler group or
      school.
    </Content>

    <Content><b>Please discuss with me if you need to change your contracted hours.</b></Content>
    <SubHeading>Collection Procedure</SubHeading>
    <Content>
      I will only release your child from my care to adults who have permission to collect him/her.
      I will therefore
      need you to provide me with a list of people authorised to collect. It would be helpful, if
      they are not known to
      me, to include a description or a photograph for me to keep on file. In the event of an
      emergency, I operate a
      password system where you can send someone not previously authorised to collect your child but
      who is able to give
      the password. If this situation arises I would require a phone call to notify me who is
      collecting with a
      description of the person and their name.
    </Content>

    <SubHeading>Late Collection</SubHeading>
    <Content>
It is important that you arrive at the contracted time to collect your child. Even very
      young children
      learn our routine and know when their parents are due and they can become distressed if you
      are late. I know
      sometimes delays are unavoidable, especially if you are relying on public transport. If you
      are delayed, for
      whatever reason please contact me and let me know when you expect to arrive. I will normally
      be able to
      accommodate the additional care, however if I am unable, please contact an adult from the
      authorised list and
      arrange for them to collect your child. I will reassure your child that you are on the way and
      if necessary
      organise additional activities and a meal. If a child is not collected within 15 minutes of
      the agreed collection
      time and I have not heard from you, I will try to make contact with you. If I cannot reach you
      on the contact
      numbers, and after 30 minutes from the agreed collection time, then I will try the emergency
      contact numbers.
      During this time, I will continue to safely look after your child. I will continue to try the
      contact numbers and
      emergency numbers, but if I have heard nothing after 1 hour from the original agreed
      collection time, I will then
      inform the local authority duty social worker and follow their advice. (In the case of a
      terrorist attack or
      National Emergency then this procedure will not be put into practice-see separate
      policy)
    </Content>

    <Content>
      <b>
I reserve the right to make an additional charge for late collection or earlier drop
      off at £5 per 15
      minutes. No
      charge will be made for occasional late collection. Late fees will be charged at my
      discretion.
      </b>
    </Content>
  </div>
);

export default collection;
