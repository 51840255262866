import React from 'react';
import { Content, SubHeading } from '../Style';

const bullying = () => (
  <div>
    <SubHeading>Bullying Policy</SubHeading>
    <Content>I will not permit any form of bullying</Content>
    <Content><b>Bullying includes</b></Content>
    <Content>
Physical:
      <li>pushing</li>
      <li>kicking</li>
      <li>hitting</li>
      <li>biting etc</li>
    </Content>
    <Content>
Verbal:
      <li>Name-calling</li>
      <li>sarcasm</li>
      <li>rumour spreading and teasing</li>
    </Content>
    <Content>
Emotional:
      <li>Exclusion</li>
      <li>ridicule</li>
      <li>humiliation</li>
      <li>tormenting</li>
      <li>Racist or Homophobic: taunts, graffiti and gestures</li>
    </Content>

    <Content>
Being bullied can result in the victim having depression, low self-esteem, shyness,
      poor academic
      achievement, isolation and in extreme cases threatened or attempted suicide. If I have any
      concerns that a child
      in my care is being bullied at pre-school or is bullying, I will discuss the matter with you
      immediately. I will
      work with you to support your child to resolve the problem.
    </Content>

    <SubHeading>If your child is being bullied</SubHeading>
    <Content>
I will reassure them that the bullying is not their fault. Tell them that I care about
      them and am 100% on
      their side. I will give them lots of praise, encouragement and responsibilities to help them
      feel valued. I will
      work with you to help the child to develop techniques to deal with the bully- assertiveness,
      walking away etc. If
      a bully is left unchecked they will learn they can get away with violence and
      aggression.
    </Content>

    <SubHeading>If your child is the bully</SubHeading>
    <Content>
I will reassure your child that I still care about them but it is their behaviour I
      don’t like and I will
      work with them to help change this. I will work with your child to find ways to make amends
      for their actions. I
      will develop a reward structure for good behaviour. I will discuss the matter with you, not in
      front of your
      child, to see if there are any problems that may have triggered the bullying. If you have any
      concerns regarding
      your child please discuss them with me as soon as possible. It is much better to deal with
      these problems before
      they become major issue.
    </Content>
  </div>
);

export default bullying;
