import React from 'react';
import { Content, SubHeading } from '../Style';

const hands = () => (
  <div>
    <SubHeading>Health and Safety Policy</SubHeading>
    <Content>
      The Health and Safety of your child is very important to me and I have therefore documented the following
      procedures
      that I have in place to support this:
      All toys and equipment will be checked and cleaned regularly to ensure they are safe for your child to use. Any
      broken or hazardous toys will be removed immediately. Children will only be offered toys and resources that are
      suitable for their age/stage of development. I complete a risk assessment of my home every morning before the
      children arrive to ensure that it is a safe environment for minded children. All equipment is fitted with the
      correct safety harnesses to prevent accidents, for example highchair and pushchairs.
    </Content>
    <Content>
      <SubHeading>Car Journeys</SubHeading>
      Car seats are checked regularly to ensure they are correctly fitted. My car is regularly serviced and is subject
      to an annual MOT. I keep the child-locks on the car engaged. My car is insured for Business use. I use safety
      equipment

      appropriate for the children in my care, i.e. Stair gates, cupboard locks etc, these are checked regularly. I will
      keep my front door locked with the keys on a hook up high to prevent the children opening the door. I have
      procedures in place in the event of a fire (see separate policy). I keep my kitchen clean and follow hygiene
      guidelines on the storing of food, keeping the fridge at the correct temperature etc. I do not permit smoking in
      my
      home (see separate policy).
    </Content>
    <SubHeading>Away from the House</SubHeading>
    <Content>
      Children must stay with me when we are away from the home. When required for their safety, younger children will
      be
      strapped in a pushchair, older children will be holding onto my hand/pushchair or will either be on a harness or
      wrist strap if they cannot walk safely with me. I have emergency contact details with me at all times should I
      need
      to contact the parents. I will work with you to teach the children about safety issues like crossing the road and
      stranger danger. I will work with you to teach the children about making healthy food choices and physical
      exercise.
      Sleeping children will be regularly checked and I also use a baby monitor. I discourage the children from keeping
      ‘secrets’. I will restrain a child if they are putting themselves or others in danger, for example running into a
      road.
    </Content>
    <SubHeading>Bikes and Scooters</SubHeading>
    <Content>
      Any child who wishes to use their scooter or bike will be required to wear a helmet.
      <b>
        {' '}
This must be provided by
      you.
      </b>
    </Content>
  </div>
);

export default hands;
