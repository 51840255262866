import React from 'react';
import { Content, SubHeading } from '../Style';

const sunprotection = () => (
  <div>
    <SubHeading>
      Sun Protection Policy
    </SubHeading>
    <Content>
      <b>
        The damaging effects of exposure to the sun on young skin have recently been well documented. I will do
        everything I can, working in partnership with you, to ensure that your child is protected.
      </b>
    </Content>
    <Content>
      I will need you to provide me with:
    </Content>
    <Content>
      Sun cream suitable for your child (high factor) which is clearly named Sun hat (if it’s a cap, preferably a
      legionnaires hat to protect the neck) which is clearly named. A light weight top/cardigan/t-shirt with long
      sleeves which is clearly named. I will require you to sign a form giving me permission to apply the sun cream that
      you have provided. I will ensure that your child uses the sun cream that you have provided and wears the hat when
      appropriate. I will also avoid spending prolonged periods of time outdoors during the hottest part of the day, and
      when out will seek to protect your child by finding shady areas and using a sunshade on the pushchair. I will
      encourage your child to drink water regularly to prevent dehydration.
    </Content>
    <Content>
      <b>
        If you forget to supply sun cream I will provide a high factor sun cream that I use for my own children. I
        reserve the right to add a charge to your next invoice of £1.50 per day to cover the cost of the suncream.
      </b>
    </Content>
    <Content>
      I ask that you apply sun cream to your child before they are dropped off to me, when appropriate.
    </Content>
  </div>
);

export default sunprotection;
