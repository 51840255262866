import React from 'react';
import styled from 'styled-components';
import { MainHeading, Cell, DailyColumnHeader, DailyRowHeader, DailyTable  } from '../Style';

export const dailyRoutine = () => (
  <>
    <MainHeading>Example Daily Routines</MainHeading>
    <DailyTable>
      <tbody>
        <tr>
          <DailyColumnHeader />
          <DailyColumnHeader>MONDAY</DailyColumnHeader>
          <DailyColumnHeader>TUESDAY</DailyColumnHeader>
          <DailyColumnHeader>WEDNESDAY</DailyColumnHeader>
          <DailyColumnHeader>THURSDAY</DailyColumnHeader>
          <DailyColumnHeader>FRIDAY</DailyColumnHeader>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>Before School</DailyRowHeader>
          </Cell>
          <Cell>Breakfast and Free play</Cell>
          <Cell>Breakfast and Free play</Cell>
          <Cell>Breakfast and Free play</Cell>
          <Cell>Breakfast and Free play</Cell>
          <Cell>Breakfast and Free play</Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>08:30am - 09:00am</DailyRowHeader>
          </Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>Morning</DailyRowHeader>
          </Cell>
          <Cell>Toddler Group including a drink and a snack</Cell>
          <Cell>Singing Group to include and drink and a snack</Cell>
          <Cell>
          Family Centre for play session and singing to include a drink and a
          snack
          </Cell>
          <Cell>Outing to the park to include a drink and a snack</Cell>
          <Cell>Planned and Free play to include a drink and a snackZ</Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>Lunch Time</DailyRowHeader>
          </Cell>
          <Cell>
          Plough mans
            <br />
          Yogurt
            <br />
          Fruit
            <br />
          </Cell>
          <Cell>
          Ham sandwich
            <br />
          Salad sticks
            <br />
          Jelly
            <br />
          Fruit
            <br />
          </Cell>
          <Cell>
          Hummus with pitta bread
            <br />
          Rice pudding
            <br />
          Fruit
            <br />
          </Cell>
          <Cell>
          Cheese wrap
            <br />
          Salad sticks
            <br />
          Banana and custard
            <br />
          </Cell>
          <Cell>
          Muffin pizza and veg
            <br />
          Ice lollies
            <br />
          Fruit
            <br />
          </Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>Afternoon</DailyRowHeader>
          </Cell>
          <Cell>
          Planned and free play and any sleeps that the children may need
          </Cell>
          <Cell>
          Planned and free play and any sleeps that the children may need
          </Cell>
          <Cell>
          Planned and free play and any sleeps that the children may need
          </Cell>
          <Cell>
          Planned and free play and any sleeps that the children may need
          </Cell>
          <Cell>
          Planned and free play and any sleeps that the children may need
          </Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>03:00pm - 03.30pm</DailyRowHeader>
          </Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
          <Cell>School Run</Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>After School</DailyRowHeader>
          </Cell>
          <Cell>Planned and free play</Cell>
          <Cell>Planned and free play</Cell>
          <Cell>Planned and free play</Cell>
          <Cell>Planned and free play</Cell>
          <Cell>Planned and free play</Cell>
        </tr>
        <tr>
          <Cell>
            <DailyRowHeader>Tea Time</DailyRowHeader>
          </Cell>
          <Cell>Cottage pie with fresh vegetables</Cell>
          <Cell>Pasta bake with garlic bread</Cell>
          <Cell>Sausage, mash potato and peas</Cell>
          <Cell>Chicken casserole with fresh vegetables</Cell>
          <Cell>Breaded fish and chips</Cell>
        </tr>
      </tbody>
    </DailyTable>
  </>
);

export default dailyRoutine;

// const DailyTable = styled.table`
//   display: flex;
// `;
//
// const DailyRowHeader = styled.td`
//   display: flex;
//   padding: 10px;
//   background-color: #00538b;
//   color: #fcf5d5;
//   font-family: "Amatic SC", cursive;
//   font-weight: bold;
//   font-size: large;
// `;
//
// const DailyColumnHeader = styled.td`
//   padding: 10px;
//   background-color: #00538b;
//   color: #fcf5d5;
//   font-family: "Amatic SC", cursive;
//   font-weight: bold;
//   font-size: large;
// `;
//
// const Cell = styled.td`
//   font-family: "Shadows Into Light", cursive;
//   font-size: 18px;
//   padding: 5px;
// `;
