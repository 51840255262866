import React from 'react';
import { Content, SubHeading } from '../Style';

const physicalcontact = () => (
  <div>
    <SubHeading>
      Physical Contact Policy
    </SubHeading>
    <Content>
      As an Ofsted registered Childminder I am very aware that each child has differing needs. Some children like to be
      affectionate and show it through hugs, kisses etc others are not so tactile. I am happy to hug, kiss (head or
      cheek) hold hands, cuddle, tickle etc your child providing both you and your child are happy with this. I would
      never force a child to do any of the above if it made them feel uncomfortable.
    </Content>
    <Content>
      I will restrain a child only if they are at risk of inflicting harm on themselves or others. I will separate
      children if they are fighting. I will restrain a child if they try and run into the road etc. If I do need to
      restrain your child I will document it in my incident book and asked you to sign the record. This is to protect
      all parties.
    </Content>
    <Content>
      I will also need to have some physical contact with your child in order to ensure hygiene routines are carried
      out. For example the washing of hands, faces and the wiping of noses. I am happy to assist with toileting
      according to the age and stage of ability of the child and to change nappies if required. If necessary I will
      change a child’s clothes if they have had an accident. Please ensure your child has a full change of clothes with
      them every day.
    </Content>
  </div>
);

export default physicalcontact;
