import React from 'react';
import { Content, SubHeading } from '../Style';

const fees = () => (
  <div>
    <SubHeading>Fees Policy</SubHeading>
    <Content>
      I would be grateful if you could ensure that you are prompt in paying your monthly / weekly* invoice. (*weekly
      payment is only if agreed in advance, monthly payment in advance is the norm)
    </Content>
    <Content>
      If I have not received payment within 2 days of the due date of the invoice, then I will issue a reminder and ask
      that the fees are paid within 5 days. If I still have not received the fees by the 8th day of the due date then I
      reserve the right to cease minding the child for which the fees are due until I receive payment. I reserve the
      right to make an additional charge ‘Late payment Fee’ of £10 per day as per our contract and to charge an
      additional fee to cover the cost of any bank charges I incur due to the late payment. If a parent is struggling to
      pay the fees due to unexpected circumstances then I ask that they discuss this with me as soon as possible and I
      will try to agree an arrangement for paying the fees.
    </Content>
    <SubHeading>
      How to Pay
    </SubHeading>
    <Content>
      <b>Fees are to be paid:</b>
    </Content>
    <Content>
      <li>In cash or standing order/direct payment into my bank.</li>
      <li> In advance, by the 1st day of each month.</li>
    </Content>
    <Content>
      <b>
Note: All contracted hours must be paid in full regardless of attendance unless myself / my children are ill
        and
        then no fee applies.
      </b>
    </Content>
  </div>
);

export default fees;
