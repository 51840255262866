import React from 'react';
import { Content, SubHeading } from '../Style';

const confidentiality = () => (
  <div>
    <SubHeading>Confidentiality Policy</SubHeading>
    <Content>
      Any information regarding your child or your family, given to me either verbally or in
      writing, will be treated as confidential.
    </Content>
    <Content>
      Parents will have access to their own child’s records but not to others. All documentation
      relating to your child is stored in a file, which is not accessible to any other party. I will
      not discuss your child with others unless I have permission from you, for example to take your
      baby to be weighed by the Health Visitor. I will however divulge confidential information to
      Social Services and to Ofsted if I have any concerns that your child is being abused. Please
      see my Safeguarding Policy. You will also find out confidential information about my family
      and I during the course of our working relationship and I would be grateful if you too would
      respect my family’s confidentiality. I understand that if I store personal details about other
      people on my computer or any digital format (including mobile phones and digital cameras) I am
      registered with the Information Commissioner’s Office (ICO) for Data Protection.
    </Content>
  </div>
);

export default confidentiality;
