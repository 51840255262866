import React from 'react';
import styled from 'styled-components';
import mainLogo from './Logo.png';
import SiteNavbar from '../SiteNavbar';

export const SiteTitle = () => (
  <SiteTitleBox>
    <SiteLogoContainer>
      <SiteLogo src={mainLogo} alt="Carrie Bevan Childminding" />
    </SiteLogoContainer>
    <SiteTitleText>Carrie Bevan Childminding</SiteTitleText>
    <NavbarContainer>
      <SiteNavbar />
    </NavbarContainer>
  </SiteTitleBox>
);

export default SiteTitle;

const SiteTitleBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  background-color: #337bbf;
  height: 10%;
`;

const SiteTitleText = styled.div`
    flex: 1 70%;
    font-family: Pacifico, cursive;
    color: #1c3b6d;
    font-size: 3rem;
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 10px;
    @media (max-width: 600px) {
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    }
    @media (max-width: 850px)
    {
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    }
`;

const SiteLogoContainer = styled.div`
  flex: 1 5%;
`;

const SiteLogo = styled.img`
`;

const NavbarContainer = styled.div`
  flex: 2 100%;
`;
