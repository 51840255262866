import React from 'react';
import { Content, SubHeading } from '../Style';

const lostchild = () => (
  <div>
    <SubHeading>
      Lost Child Policy
    </SubHeading>
    <Content>
      The care of your child is paramount and I will endeavour to ensure that they remain with me and are safe by
      implementing the following measures:
      Ensuring the children hold my hand or the pushchair whilst we are out. Avoid going to places that are overcrowded.
      On outings the children wear wristbands with my mobile number on them and fluorescent jackets if I feel that this
      is
      appropriate. I teach the children about the dangers of wandering off and of talking to strangers. In the unlikely
      event that a child does get lost, the following procedure will be implemented:
    </Content>
    <Content>
      I will immediately raise the alarm to all around me that I have lost a child and enlist the help of everyone to
      look
      for them. If it is a secure area such as a soft play area, I will quickly alert the staff so they can seal off
      exits
      and monitor the situation on any CCTV. I will provide everyone involved in the search with a description of the
      child. I will reassure the other children with me, as they may be distressed. I will then alert the police and
      provide a full description. I will then alert the parents of the situation.
    </Content>
  </div>
);

export default lostchild;
