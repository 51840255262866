import React from 'react';
import { Content, SubHeading } from '../Style';

const garden = () => (
  <div>
    <SubHeading>Garden Equipment Policy</SubHeading>
    <Content>
      In my garden I have a range of outdoor toys and equipment. Whilst this provides lots of opportunities for
      developing new physical skills and enjoyment it also brings an element of risk. Children need to learn about
      danger and risk taking; however in order to make my garden a safe environment and to reduce the risk of possible
      accidents I have put the following procedures in place:
    </Content>
    <SubHeading>All minded children will be supervised by a responsible adult at all times</SubHeading>
    <Content>
      Children will be taught the dangers of the equipment in a way that is suitable for their stage of development and
      understanding. Children will be encouraged to take turns and share equipment. The equipment will be
      checked/cleaned before use for animal faeces. The equipment will be checked for wear and tear/ damage regularly
      and withdrawn from use if faulty. Children will be encouraged to participate but will not be made to do any
      activity that they are uncomfortable with. Children will wear protective clothing when using bikes, scooters and
      skateboards such as helmets.
    </Content>
    <Content>
      If you have any concerns regarding your child using any piece of equipment in my garden please do discuss them
      with me.
    </Content>
  </div>
);

export default garden;
