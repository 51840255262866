import React from 'react';
import { Highlight } from '../Style';

export const highlight = (props) => (
  <>
    <Highlight>{props.content}</Highlight>
  </>
);

// highlight.PropTypes = {
//   content: PropTypes.string.isRequired
// };

export default highlight;
