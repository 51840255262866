import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

class SiteNavbar extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
  }

  routeChange = e => {
    this.props.history.push(e);
  };

  render() {
    return (
      <SiteNavbarStyle>
        <NavbarButton onClick={() => this.routeChange('/')}>
          Home
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/fees')}>
          Fees
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/contact')}>
          Contact Me
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/references')}>
          References
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/menu')}>
          Example Menu
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/rules')}>
          House Rules
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/dailyroutines')}>
          Example Routines
        </NavbarButton>
        <NavbarButton onClick={() => this.routeChange('/policies')}>
          Policies
        </NavbarButton>
      </SiteNavbarStyle>
    );
  }
}

export default withRouter(SiteNavbar);

const SiteNavbarStyle = styled.div`
  display: flex inline;
  background-color: #1c3b6d;
  flex-wrap: wrap-reverse;
`;

const NavbarButton = styled.button`
  font-family: "Amatic SC", cursive;
  font-size: 1.75rem;
  padding: 0.25rem;
  background-color: transparent;
  color: antiquewhite;
  border-color: transparent;
  border-bottom: 0.25rem solid #1c3b6d;
  :hover {
    color: orange;
    border-bottom: 0.25rem solid orange;
  }
  @media (max-width: 600px) {
    font-size: 1.25rem;
   
  }
`;
