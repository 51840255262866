import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

export const Highlight = styled.div`
  font-family: "Lato", sans-serif;
  color: orange;
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
  margin: 0.66rem;
  border-bottom-color: orange;
  border-bottom-style: dotted;
  border-bottom-width: 0.33rem;
  border-top-color: orange;
  border-top-style: dotted;
  border-top-width: 0.33rem;
  background-color: #fcf5d5;
  @media (max-width: 600px) {
    font-size: 1.35rem;
  }
`;


export const Content = styled.p`
  font-family: "Kalam", cursive;
  font-size: 1.25rem;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const MainHeading = styled.h2`
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  font-size: 2rem;
  color: #00538b;
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const SubHeading = styled.h3`
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  font-size: 1.5rem;
  color: #00538b;
  @media (max-width: 600px) {
    font-size: 1rem;

  }
`;


export const InfoBox = styled.div`
  padding-bottom: 1rem;
`;

export const FooterText = styled.p`
  font-family: "Source Sans Pro", sans-serif;
  margin: 1.5rem;
  font-size: 0.75rem;s
`;

export const FooterBox = styled.div`
  padding: 2rem;
  background-color: #337bbf;
`;


export const PolicyBox = styled.div`
  padding: 10px;
  background-color: #1c3b6d;
`;

export const StyledLink = styled(Link)`
  color: antiquewhite;
  padding:6.6px;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  background-color: #1c3b6d;
  display: inline-flex;
  border-bottom: 5px solid #1c3b6d;
  :hover {
    color: orange;
    border-bottom: 5px solid orange;
    }
`;

export const DailyTable = styled.table`
  display: flex;
`;

export const DailyRowHeader = styled.td`
  display: flex;
  padding: 10px;
  background-color: #00538b;
  color: #fcf5d5;
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  font-size: large;
`;

export const DailyColumnHeader = styled.td`
  padding: 10px;
  background-color: #00538b;
  color: #fcf5d5;
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  font-size: large;
`;

export const Cell = styled.td`
  font-family: "Kalam", cursive;
  font-size: 18px;
  padding: 5px;
`;
