import React from 'react';
import { Content, SubHeading } from '../Style';

const phonecamera = () => (
  <div>
    <SubHeading>
      Mobile Phone and Camera Policy
    </SubHeading>
    <Content>
      I understand that mobile phones are an everyday part of life for parents and childminders and with that in mind
      have laid out my procedure for their use. I will ensure my mobile phone is fully charged and with me at all times
      in case of emergencies. I have the facility to take photographs on both my mobile phone and camera and will seek
      your permission to take any photographs of your child to record activities and share their progress with you. Any
      photographs taken will be deleted after either emailing to you or printed for use in your child’s learning
      journey. While on the computer they will be encrypted. I will not publish any photographs of your child on any
      social networking sites or share with any other person without your permission.
    </Content>

    <Content>
      Ensuring that visitors to my home during Childminding hours are asked to keep their phones in their bags and to
      take calls away from the children. Ensuring my phone is kept out of the reach of children. Pictures are taken only
      by me with the written permission of parents. Parents indicate if they are willing for photos of their children to
      be used for: sharing with them, supporting Observation, Assessment & Planning; children’s records. I will avoid
      putting myself into any compromising situation which could be misinterpreted and lead to possible allegations.
    </Content>
    <Content>
      Pictures are transferred to my computer which is password protected. I have registered with the Information
      Commissioner’s Office for Data Protection. Any stored pictures are deleted once a child leaves. Any visitors to my
      setting are not allowed to take photos with their mobile phones or cameras. This is to safeguard all children in
      my care. Older children with mobile phones are required to keep them in their bags away from the other children.
      Please discuss with me if your child wishes to bring a mobile phone or camera to my setting.
    </Content>
  </div>
);

export default phonecamera;
