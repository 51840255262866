import React from 'react';
import { Content, SubHeading } from '../Style';

const asthma = () => (
  <div>
    <SubHeading>Asthma Policy</SubHeading>
    <Content>
I request that I am also given an additional inhaler to keep at my house, in case of
      the child forgetting
      to bring it with them.
    </Content>

    <Content>
I am willing to care for a child who has asthma and have put together the following
      policy and procedures.
      I recognise that asthma is an important condition that affects many children.
    </Content>

    <Content><b>I will:</b></Content>
    <Content>
Encourage and help children with asthma to participate fully in activities. Ensure
      children have immediate
      access to reliever inhalers. This may include allowing them to carry it on them either in
      their pocket or inhaler
      pouch if they are mature enough to do this. If, after discussion between the parents/carers
      and the Doctor or
      Nurse, it is believed that a particular child is too young to carry their inhaler, I will keep
      it with me at all
      times.
    </Content>

    <Content>
Ensure the environment is favourable to children with asthma. Ensure that other
      children in my care
      understand that asthma can be serious. Ensure that I know what to do if a child has an asthma
      attack to protect
      the child’s welfare in the event of an emergency. Please let me know if your child needs to be
      reminded to take
      their inhaler and if they need to do it privately. I appreciate some children are shy about
      taking their
      medication in front of others. I will always inform parents/carers if their child has
      experienced asthma symptoms
      and had to use their reliever medicine. I ask parents to tell me about their child’s asthma
      symptoms, how to
      recognise when their symptoms are getting worse and how to help them take their reliever
      medicine. I will record
      this information on the child’s personal record form.
    </Content>

    <SubHeading>I need parents to provide written information detailing:</SubHeading>
    <Content>
      <b>
        <li>What asthma medicines the child takes and when?</li>
      </b>
    </Content>

    <Content>
      <b>
        <li>What triggers the child’s asthma?</li>
      </b>
    </Content>

    <Content>
      <b>
        <li>What to do if the child’s asthma gets worse?</li>
      </b>
    </Content>

    <Content>
      <b>
        <li>Parents/carers emergency contact details</li>
      </b>
    </Content>
  </div>
);

export default asthma;
