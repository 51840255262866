import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import styled from 'styled-components';
import SiteTitle from './sitetitle/SiteTitle';
import SiteFooter from './sitefooter/SiteFooter';
import Home from './home/Home';
import Contact from './contact/Contact';
import References from './references/References';
import Menu from './menu/Menu';
import Rules from './rules/Rules';
import dailyRoutines from './dailyroutine/DailyRoutine';
import Fees from './fees/Fees';
import Info from './info/Info';
import News from './news/News';
// import {policies} from './policiesfile';
import Policies from './policies/Policies';

const Main = () => (
  <Router>
    <SiteTitle />
    <PrimeContainer>
      <SiteMainCol>
        <Route path="/" component={Home} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/references" component={References} />
        <Route path="/menu" component={Menu} />
        <Route path="/rules" component={Rules} />
        <Route path="/dailyroutines" component={dailyRoutines} />
        <Route path="/fees" component={Fees} exact />
        <Route path="/policies" component={Policies} />
      </SiteMainCol>
      <SiteInfoColumn>
        <Info />
        <News Heading="Welcome to our new website.">
          We took a look at our old website and realised that it was a bit
          slow, so this is our brand new custom built site, built using the
          latest technology to make it better and easier for you to find out
          more about us and find the information that you need.
        </News>
      </SiteInfoColumn>
    </PrimeContainer>
    <SiteFooter />
  </Router>
);

export default Main;

const PrimeContainer = styled.div`
  display: flex inline;
  padding: 5px;
`;

const SiteMainCol = styled.div`
  float: left;
  width: 72%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const SiteInfoColumn = styled.div`
  float: right;
  width: 22%;
  padding: 25px;
  background-color: #fcf5d5;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: palegoldenrod;

  @media (max-width: 1100px) {
    width: 85%;
    float: left;
    margin: 5%;
    border-width: 5px;
    border-style: solid;
    border-color: palegoldenrod;
  }
`;
