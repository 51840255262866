import React from 'react';
import { Content, SubHeading } from '../Style';

const behaviour = () => (
  <div>
    <SubHeading>Behaviour Policy</SubHeading>
    <Content>
As a registered childminder I recognise the need to set out reasonable and appropriate
      limits to manage the
      behaviour of children in my care. The environment I provide within my home is of a relaxed and
      positive nature.
      Children need to know what the boundaries and expectations are within my home and I will
      ensure that each child
      knows the house rules that I have set. I aim to encourage each child in my care to grow and
      develop into
      respectful young people who are well equipped with the social skills needed for them to be
      welcomed into society.
      I will ensure that my approach to this is consistent and that every child is treated in the
      same manner.
    </Content>

    <SubHeading>My Procedures</SubHeading>
    <Content>
I keep up to date with behaviour management issues and relevant legislation by taking
      regular training and
      by reading relevant publications from Ofsted. All parents have access to a copy of my
      Behaviour Policy. A copy can
      be printed out on request. I will not administer physical punishment, cause pain, discomfort,
      humiliate or hurt
      any child in my care. I endorse positive discipline as an effective way of setting boundaries
      for children. I aim
      to be firm and consistent so that children know and feel secure within the boundaries I
      set.
    </Content>

    <Content><b>Promoting positive behaviour is very important and I do this by:</b></Content>
    <Content>
Giving lots of praise for good behaviour. I believe children thrive from positive
      encouragement and
      therefore I will praise a child when they have done something well. Providing a routine.
      Children need stability
      and routines so that they know what to expect. They are more likely to display positive
      behaviours when they have
      a good routine and structure to their day. Giving the children individual attention. Good
      behaviour is centred on
      a child feeling valued and respected.Setting a good example. Children are very observant and
      watch everything we
      do as adults and like to imitate our behaviour. It is important that they have good role
      models around them
      Listening to what the children have to say and provide an age appropriate explanation to
      them.
    </Content>

    <Content>
Children should be allowed to voice an opinion so they feel they are being heard, in
      turn, they are more
      likely to listen. I feel that children should be given an explanation as to why a behaviour is
      unacceptable and
      what the consequences could be. In my experience saying “because I said so” does not provide
      the child with a
      reason as to why they should not behave in a certain way and they are more likely to repeat
      the same pattern of
      behaviour. I will help children maintain their self-esteem by showing I disapprove of
      challenging behaviour, not
      the child themselves.
    </Content>

    <Content><b>I will do this by:</b></Content>
    <Content>
      <li>
Setting boundaries that are age and development appropriate and apply them consistently.
        It is essential that
        children know what the expectations are of them. Boundaries encourage respect for others.
        Rewarding good
        behaviour.This may be in the form of simple praise, a sticker, or choosing the next
        activity. Children are more
        likely to repeat good behaviour if they have had positive praise for doing so.
      </li>

      <li>
I will only physically intervene or restrain a child to prevent an accident, such as a
        child running into the
        road, or to prevent an injury or damage. I will record when this occurs and inform parents
        on the same day, or
        as soon as reasonably practical. I record all significant behavioural incidents in an
        incident book. I will
        discuss these with the parents of the child concerned so that together we can work to
        resolve any behavioural
        issues
      </li>

      <li>
I acknowledge the strength and range of children’s feelings and try to help children to
        find constructive
        solutions for managing these. I encourage responsibility by talking to children about
        choices and their possible
        consequences.
      </li>
      <li>
I agree methods to manage children’s behaviour with parents before the placement starts.
        These are discussed
        with parents during initial visits before the contract is signed and recorded in the child’s
        file.
      </li>

      <li>
If children do misbehave I will use one of the following strategies
        Remove the child from the situation and give them an alternative activity. Ignore. Depending
        on the situation I
        may ignore the bad behaviour as I feel it is being done to get a reaction.
      </li>

      <li>
Discussion - If the child is able to understand I will discuss their behaviour with them
        and try to get them
        to appreciate the consequences of their actions. I inform them that it is their behaviour
        that I do not like and
        not the child themselves.Time Out. Removing the child from the activity and sitting them
        quietly for a few
        minutes. This is referred to as Thinking Time.
      </li>

      <li>
Wherever possible I try to meet parents’ requests for the care of their children according
        to their values and
        practices. Records of these requirements are agreed and kept attached to the child record
        forms. These records
        are revisited and updated during regular reviews with parents.
      </li>
    </Content>

    <Content>
I ask parents to respect that this is my home and I have some behaviour which I find
      unacceptable, such as
      climbing on furniture. These will be discussed before the child starts.
    </Content>

    <Content>
I request parents inform me of any changes in the child’s home circumstances, care
      arrangements or any
      other change which may affect the child’s behaviour such as a new home, a new baby, parents’
      separation, divorce,
      new partner or any bereavement. All information shared will be kept confidential unless there
      appears to be a
      child protection issue.
    </Content>

    <Content>
I also ask parents to inform me of any changes in behaviour they have noticed at home
      so we can work in
      partnership in dealing with any problems the behaviour may cause. I work together with parents
      to make sure there
      is consistency in the way the children are cared for. A consistent approach benefits the
      child’s welfare and helps
      ensure that the child is not confused. I offer regular review meetings with parents to discuss
      their child’s care
      and any issues or concerns, preferably when the child is not present. These can be done in
      person or by
      phone.
    </Content>

    <Content>
If I have concerns about a child’s behaviour which I cannot resolve in partnership with
      parents, I will ask
      for permission from the parents to talk it through with another childcare professional. I may
      call the NSPCC,
      health visitor or the local early years team (or other relevant advice service) for
      confidential advice.
    </Content>
  </div>
);

export default behaviour;
