import React from 'react';
import Highlight from '../highlight/Highlight';
import { Content, MainHeading } from '../Style';

const today = new Date();
const myBirthday = new Date('1981-08-05');
const daughterBirthday = new Date('2013-03-14');
const sonBirthday = new Date('2016-02-09');
const startedChildminding = new Date('2002-07-20');

const myAge = () => today.getFullYear() - myBirthday.getFullYear();

const sonAge = () => today.getFullYear() - sonBirthday.getFullYear();

const daughterAge = () => today.getFullYear() - daughterBirthday.getFullYear();

const yearsChildminding = () => today.getFullYear() - startedChildminding.getFullYear();

export const Home = () => (
  <div>
    <Highlight
      content="Please contact me to talk about how I can help with your Childcare needs. I can offer flexible 7am to 7pm full day, part time or after school places to fit around your needs."
    />
    <MainHeading>Welcome</MainHeading>
    <Content>
      I’m Carrie Bevan, a childminder in the Burgess Hill area. Fully Ofsted
      registered and First Aid trained, with additional Epi pen training.
      {' '} {myAge()}{' '} years old, married with two children, a {daughterAge()}
      {' '} year old daughter and {sonAge()}{' '} year old son. I have been looking after children for the last {' '}
      {yearsChildminding()}{' '} years. I first started working professionally with children in Nurseries for several years, then as a
      private nanny across Sussex, Kent and London.
      <br />
      My experience includes children of all ages from newborn to teenagers.
      <br />
      My qualifications include NVQ Level 2&3 in Early Years Care and Education
      and a Level 3 Award from Pacey, full first aid and epi pen trained and
      Ofsted registered.
      <br />
    </Content>
    <MainHeading>My Aim</MainHeading>
    <Content>
      I offer a home from home environment. Children will have access to a wide
      range of resources where they will be free to play while offering
      structured activities. This will enable the children to grow and develop
      covering all 7 areas of learning. My setting offers an inviting and
      stimulating playroom consisting of a range of toys and activities,
      available to everyone. All children will have the opportunity to explore
      the outdoors by having access to my all year round garden, the recreation
      ground or in parks.
      <br />
      I have a 7 seat car which means I am able to collect from pre-schools and
      schools within the Burgess Hill area.
      <br />
      It is important to me that all children in my setting feel safe and happy.
      <br />
    </Content>
  </div>
);

export default Home;
