import React from 'react';
import { Content, SubHeading } from '../Style';

const complaints = () => (
  <div>
    <SubHeading>Complaints Procedure</SubHeading>
    <Content>
      I hope that you are happy with the service that I provide, but I appreciate there may be times
      when you feel that I am not offering you and your child(ren) the service that you require. I
      hope that you will feel able to discuss any concerns or issues that you may have with me
      directly.
    </Content>
    <Content>
      If you would rather not talk in front of your child(ren) then we can arrange a more convenient
      time, for example in the evening or at the weekend.It is a requirement by Ofsted that all
      complaints are logged along with the outcome and any action taken. These records must be
      available to show an Ofsted Childcare Inspector if required.
    </Content>
    <Content>
      If you feel that you are unable to talk to me or that after talking the matter remains
      unresolved then
      you can talk in confidence to: The National Childminding Association on 0208 464 6164 or The
      Early Years
      Childminding Team on 0208 464 9037
      If you wish to make a formal complaint then you can contact the Ofsted Complaints and
      Investigation Unit on 0845
      640 40 40
    </Content>
  </div>
);

export default complaints;
