import React from 'react';
import { Content, SubHeading } from '../Style';

const healthyeating = () => (
  <div>
    <SubHeading>Healthy Eating Policy</SubHeading>
    <Content>
      A good balanced diet is vital for children to develop healthily along with fresh air and regular exercise.
    </Content>
    <Content>
      I will provide all children in my care with healthy meals, snacks and drinks, or to cook / serve food provided by
      their parents. I encourage children to choose healthy options and to experiment by trying new foods from other
      cultures. Older children are encouraged to help in the preparation of food and meals. I am happy to discuss menus
      with parents and cater for children with different food allergies and cultural/religious requirements. If your
      child has any particular dislikes or favourite dishes please let me know. If you have any concerns regarding diet
      / menu / quantity please do not hesitate to discuss it with me. I do not permit children to have fizzy drinks.
      Children are offered water, milk or squash. I do not encourage children to eat sweets on a regular basis; however
      I do sometimes offer sweets for special occasions.
    </Content>
    <Content>
      <b>Please let me know if your child has an allergies and these will be catered for.</b>
    </Content>
  </div>
);

export default healthyeating;
