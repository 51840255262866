import React from 'react';
import { Content, InfoBox, MainHeading } from '../Style';
import kidsLine from '../AdobeStock_82873092.png';

export const Info = () => (
  <InfoBox>
    <img src={kidsLine} width="100%" alt="kids in a line" />
    <MainHeading>Contact Information</MainHeading>
    <Content>
      Carrie Bevan
      <br />
      24 Fairfield Road,
      <br />
      Burgess Hill,
      <br />
      West Sussex,
      <br />
      RH15
      8QQ
      <br />
      <br />
      <a href="tel:07702 107430">07702 107430</a>
      <br />
      <br />
      <a href="mailto:carrie@carriebevan-childminding.co.uk">carrie@carriebevan-childminding.co.uk</a>
    </Content>
  </InfoBox>
);

export default Info;
