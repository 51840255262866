import React from 'react';
import { Content, SubHeading } from '../Style';

const illness = () => (
  <div>
    <SubHeading>Absence / Illness Fee</SubHeading>
    <Content>
      In the case of sickness the following apply:
      <li>Me/My children – no fees apply</li>
      <li>Parent/their child – full fees apply.</li>
    </Content>
    <Content>
      If I am able to work and you chose not to send your child, then full
      fees apply.
    </Content>
  </div>
);

export default illness;
