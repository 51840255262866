import React from 'react';
import { Content, SubHeading } from '../Style';

const hygiene = () => (
  <div>
    <SubHeading>
      Hygiene Policy
    </SubHeading>
    <Content>
      It is very important to prevent the spread of germs and illnesses that hygiene procedures are in place and
      strictly
      adhered to.
    </Content>
    <Content>
      Children are encouraged to wash their hands after going to the toilet, touching animals and playing outside. They
      must also wash their hands before eating any meals or snacks. I will assist the children in hand washing, ensuring
      that they are washing and drying them correctly. I will provide a clean towel in the bathroom each day, however if
      a child is poorly I will provide them with their own towel as a precaution. I am happy to help children to clean
      their teeth after breakfast if you provide me with a toothbrush and paste. I will assist children with wiping
      their noses when they have colds and try to teach them how to blow their noses. I will explain to them the
      importance of safely throwing away dirty tissues to prevent the spread of germs. I will also encourage them to put
      their hand over their mouths when they cough. I will disinfect my changing mat between children. I will follow
      strict hygiene routines in my kitchen, ensuring my fridge is at the correct temperature and that food is stored
      correctly in it. In order for me to carry out these procedures effectively I will need you to provide me with
      enough resources, for example nappies, labelled cream, spare clothes for the children etc. I also request that you
      let me know if your child is feeling ill or has been unwell. I will not mind a child who has had an upset stomach
      in the last 48 hours. This is to protect myself and other children from illness.
    </Content>
  </div>
);

export default hygiene;
