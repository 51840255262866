import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MainHeading, PolicyBox, StyledLink } from '../Style';
import illness from './illness';
import accident from './accident';
import asthma from './asthma';
import drugs from './drugs';
import behaviour from './behaviour';
import bullying from './bullying';
import complaints from './complaints';
import confidentiality from './confidentiality';
import collection from './collection';
import fees from './fees';
import emergency from './emergency';
import garden from './garden';
import hands from './hands';
import healthyeating from './healthyeating';
import physicalcontact from './physicalcontact';
import holidays from './holidays';
import hygiene from './hygiene';
import inclusion from './inclusion';
import lostchild from './lostchild';
import medicine from './medicine';
import phonecamera from './phonecamera';
import outings from './outings';
import safeguarding from './safeguarding';
import schoolholiday from './schoolholiday';
import sickeness from './sickeness';
import sleepingbaby from './sleepingbaby';
import television from './television';
import sunprotection from './sunprotection';
import transportation from './transportation';
import visitors from './visitors';
import parentpartnership from './parentpartnership';


class Policies extends Component {
  render() {
    return (
      <div>
        <Router>
          <MainHeading>Carrie Bevan Childminding Policies</MainHeading>
          <PolicyBox>
            <StyledLink to="/policies/illness">Absence and Illness</StyledLink>
            <StyledLink to="/policies/accident">Accident and Incident Policy</StyledLink>
            <StyledLink to="/policies/drugs">Alcohol, Smoking and Drugs Policy</StyledLink>
            <StyledLink to="/policies/asthma">Asthma Policy</StyledLink>
            <StyledLink to="/policies/behaviour">Behaviour Policy</StyledLink>
            <StyledLink to="/policies/bullying">Bullying Policy</StyledLink>
            <StyledLink to="/policies/complaints">Complaints Procedure</StyledLink>
            <StyledLink to="/policies/confidentiality">Confidentiality Policy</StyledLink>
            <StyledLink to="/policies/collection">Dropping off, Collection and Late Collection Policy</StyledLink>
            <StyledLink to="/policies/emergency">Emergency Care Policy</StyledLink>
            <StyledLink to="/policies/fees">Fees Policy</StyledLink>
            <StyledLink to="/policies/garden">Garden Equipment Policy</StyledLink>
            <StyledLink to="/policies/healthandsafety">Health and Safety Policy</StyledLink>
            <StyledLink to="/policies/healthyeating">Healthy Eating Policy</StyledLink>
            <StyledLink to="/policies/holidays">Holiday Policy</StyledLink>
            <StyledLink to="/policies/hygiene">Hygiene Policy</StyledLink>
            <StyledLink to="/policies/inclusion">Inclusion Policy</StyledLink>
            <StyledLink to="/policies/lostchild">Lost Child Policy</StyledLink>
            <StyledLink to="/policies/medicine">Medicine Policy</StyledLink>
            <StyledLink to="/policies/phonecamera">Mobile Phone and Policy</StyledLink>
            <StyledLink to="/policies/outings">Outings Policy</StyledLink>
            <StyledLink to="/policies/physicalcontact">Physical Contact Policy</StyledLink>
            <StyledLink to="/policies/safeguarding">Safeguarding Children Policy</StyledLink>
            <StyledLink to="/policies/schoolholiday">School Holiday Care Policy</StyledLink>
            <StyledLink to="/policies/sickeness">Sickness Policy</StyledLink>
            <StyledLink to="/policies/sleepingbaby">Sleeping Baby Policy</StyledLink>
            <StyledLink to="/policies/sunprotection">Sun Protection Policy</StyledLink>
            <StyledLink to="/policies/television">Television Policy</StyledLink>
            <StyledLink to="/policies/transportation">Transportation of Children in a Car Policy</StyledLink>
            <StyledLink to="/policies/visitors">Visitors Policy</StyledLink>
            <StyledLink to="/policies/parentpartnership">Working in Partnernship with Parents Policy</StyledLink>
          </PolicyBox>

          <Switch>
            <Route path="/policies/illness" component={illness} />
            <Route path="/policies/accident" component={accident} />
            <Route path="/policies/drugs" component={drugs} />
            <Route path="/policies/asthma" component={asthma} />
            <Route path="/policies/behaviour" component={behaviour} />
            <Route path="/policies/bullying" component={bullying} />
            <Route path="/policies/complaints" component={complaints} />
            <Route path="/policies/confidentiality" component={confidentiality} />
            <Route path="/policies/collection" component={collection} />
            <Route path="/policies/emergency" component={emergency} />
            <Route path="/policies/fees" component={fees} />
            <Route path="/policies/garden" component={garden} />
            <Route path="/policies/healthandsafety" component={hands} />
            <Route path="/policies/healthyeating" component={healthyeating} />
            <Route path="/policies/holidays" component={holidays} />
            <Route path="/policies/hygiene" component={hygiene} />
            <Route path="/policies/inclusion" component={inclusion} />
            <Route path="/policies/lostchild" component={lostchild} />
            <Route path="/policies/medicine" component={medicine} />
            <Route path="/policies/phonecamera" component={phonecamera} />
            <Route path="/policies/outings" component={outings} />
            <Route path="/policies/physicalcontact" component={physicalcontact} />
            <Route path="/policies/safeguarding" component={safeguarding} />
            <Route path="/policies/schoolholiday" component={schoolholiday} />
            <Route path="/policies/sickeness" component={sickeness} />
            <Route path="/policies/sleepingbaby" component={sleepingbaby} />
            <Route path="/policies/sunprotection" component={sunprotection} />
            <Route path="/policies/television" component={television} />
            <Route path="/policies/transportation" component={transportation} />
            <Route path="/policies/visitors" component={visitors} />
            <Route path="/policies/parentpartnership" component={parentpartnership} />
          </Switch>
        </Router>
      </div>
    );
  }
}


export default Policies;
