import React from 'react';
import { Content, MainHeading, SubHeading } from '../Style';
import kidsLine from '../AdobeStock_82873092.png';

export const News = (props) => (
  <>
    <img src={kidsLine} width="100%" alt="kids in a line" />
    <MainHeading>News</MainHeading>
    <SubHeading>{props.Heading}</SubHeading>
    <Content>{props.children}</Content>
  </>
);

// News.propTypes = {
//   Heading: propTypes.string.isRequired,
//   children: propTypes.string.isRequired
// };

export default News;
