import React from 'react';
import { Content, SubHeading } from '../Style';

const emergency = () => (
  <div>
    <SubHeading>Emergency Care Policy</SubHeading>
    <Content>
      I am happy to look after a child outside their contracted hours/days on an ad-hoc basis if I have the place
      available and it will not push me outside of my ratios as determined by Ofsted. This will be charged at normal
      hourly rate and will be added to your next invoice.
    </Content>
  </div>
);

export default emergency;
