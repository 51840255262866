import React from 'react';
import { Content, SubHeading } from '../Style';

const outings = () => (
  <div>
    <SubHeading>
      Outings Policy
    </SubHeading>
    <Content>
      <b>
It is my duty to keep every child out of harm’s way, not just in my home but also on outings. In order to meet
        the requirements of my policy I will ensure that:
      </b>
      <li>
I obtain written parental permission for all routine outings such as local groups and parks and also small
        trips
        such as to the shops and the school run. I will inform parents of any planned special trips in advance such as
        trips to the seaside and local farms.
      </li>

      <li>
A full risk assessment is completed before the outing takes place and any procedures to minimise the risks of
        any
        hazards identified have been recorded. This includes not exceeding the adult to child ratios as set out by the
        EYFS.
      </li>

      <li>
The children may wear wristbands with my mobile number on them and fluorescent jackets if I feel that this is
        appropriate. I take essential records and equipment for each child on outings as necessary, including contact
        telephone numbers for parents, a first-aid kit, sun cream, drinks and snacks, spare clothes and nappies and a
        mobile telephone.
      </li>

      <li>
I adhere to my lost child policy and make parents aware of the procedures in that policy (please see my Lost
        Child
        Policy for further information). I will endeavour to organise outings to fit in with the needs of the children,
        and ensure that I have the necessary equipment and resources to keep them safe.
      </li>
    </Content>
  </div>
);

export default outings;
