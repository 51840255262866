import React, { Component } from 'react';
import styled from 'styled-components';
import { Content, MainHeading, SubHeading } from '../Style';

class Menu extends Component {
  render() {
    return (
      <Content>
        <MainHeading>Example Daily Meals</MainHeading>
        <p>
          Breakfast, lunch, dinner and snacks are offered to all children over
          12 months old and are included in my fees.
        </p>
        <p>
          If your child is under 12 months, please talk to me about your child's
          needs. If your child has any dietary requirements or allergies then
          please discuss this with me. I offer a range of nutritious home made
          meals, that are produced by me from fresh ingredients.
        </p>
        <MenuContainer>
          <MenuRow>
            <MenuColumn>
              <SubHeading>Breakfast</SubHeading>
              <Content>
                <ul>
                  <li>Toast, with butter, jam, marmalade or Marmite</li>
                  <li>Cereals with milk</li>
                  <li>Porridge</li>
                  <li>Fruit</li>
                  <li>Milk or water to drink</li>
                </ul>
              </Content>
            </MenuColumn>
            <MenuColumn>
              <SubHeading>Lunch</SubHeading>
              <Content>
                <ul>
                  <li>Selection of sandwiches</li>
                  <li>Plough mans</li>
                  <li>Beans on toast</li>
                  <li>Scrambled egg on toast</li>
                  <li>Jacket potato with a filling</li>
                  <li>Wraps</li>
                  <li>Pitta and hummus</li>
                  <li>Cheese on toast</li>
                  <li>Muffin pizzas</li>
                  <li>Cheese and biscuits</li>
                  <li>Crumpets</li>
                  <li>Quiche</li>
                </ul>
              </Content>
            </MenuColumn>
          </MenuRow>
          <MenuRow>
            <MenuColumn>
              <SubHeading>Tea</SubHeading>
              <Content>
                <ul>
                  <li>Pasta bake and garlic bread</li>
                  <li>Sausage casserole</li>
                  <li>Bangers and mash</li>
                  <li>Fish fingers</li>
                  <li>Spaghetti Bolognese</li>
                  <li>Cottage Pie</li>
                  <li>Fish Pie</li>
                  <li>Homemade Pizza</li>
                  <li>Roast Chicken</li>
                  <li>Macaroni Cheese</li>
                </ul>
              </Content>
            </MenuColumn>
            <MenuColumn>
              <SubHeading>Puddings</SubHeading>
              <Content>
                <ul>
                  <li>Fresh fruit</li>
                  <li>Yogurts</li>
                  <li>Angel delight</li>
                  <li>Jelly</li>
                  <li>Crumble and custard</li>
                  <li>Banana and custard</li>
                  <li>ice cream</li>
                  <li>Tinned fruit and cream</li>
                  <li>Rice pudding</li>
                  <li>Cake, cookie or flapjack</li>
                </ul>
              </Content>
            </MenuColumn>
          </MenuRow>
        </MenuContainer>
        <MainHeading>Notes</MainHeading>
        <p>
          All meals will be offered with fresh vegetable or salad sticks and the
          children will be offered water to drink.
        </p>
        <p>There will be water available to drink throughout the day.</p>
      </Content>
    );
  }
}

export default Menu;

const MenuContainer = styled.div`
  display: grid;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px;
  margin: 5px;
`;

// noinspection SyntaxError
const MenuRow = styled.div`
  &&after: {
    content: "";
    clear: both;
    display: table;
  }
`;

const MenuColumn = styled.div`
  float: left;
  width: 50%;
`;
